
















































.detail
  font-size 14px

.vehicle
  height 80px
  padding 10px 8px
  transition transform .2s ease-in
  cursor pointer

.img-container
  width 72px
  height: 64px
  display: flex;
  align-items: center;
  justify-content: center;

.btn-price
  color #1e88e5!important
  border-color #1e88e5!important

.vehicle:hover .btn-price
  background #1e88e5!important
  border-color #1e88e5!important
  color white!important
