












































































































































































.groups .q-carousel-left-arrow, .groups .q-carousel-right-arrow
  background white
  top: 25px;

.taxi-tab
  width 20vw
  padding 0
