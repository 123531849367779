



























































































































































































































































@require '~snazzy-info-window/dist/snazzy-info-window.css'

.si-content-wrapper
  padding 0 !important
  border 0 !important
  box-shadow: 1px 1px 30px 0px rgba(0,0,0,0.3);
.si-close-button, .si-pointer-top, .si-pointer-left
  display none

.si-wrapper-top
  cursor pointer

.window-chevron
  font-size 25px
  vertical-align middle
  align-self center
.window-text
  display block
  padding 3px
  padding-left 10px
  line-height 2

.si-content-wrapper small
  text-transform uppercase
  padding: 9px;
  color white
  background: var(--q-color-primary, #218AE5);
