.to-results-enter-active,
.to-results-leave-active {
  transition: opacity 1s;
}
.to-results-enter,
.to-results-leave-to {
  opacity: 0;
}
.to-results-enter-to {
  opacity: 1;
}
.from-results-to-vehicle-leave-active,
.from-vehicle-to-results-leave-active {
  transition: height 0.3s, opacity 0.3s, bottom 0.3s;
}
.from-results-to-vehicle-leave-active > *,
.from-vehicle-to-results-leave-active > * {
  transition: opacity 0.3s !important;
}
.from-results-to-vehicle-leave-to {
  height: calc(100vh - 64px) !important;
  bottom: 0;
  position: absolute !important;
  background: #fff;
}
.from-results-to-vehicle-leave-to > * {
  opacity: 0;
}
@media (min-width: 768px) {
  .from-results-to-vehicle-leave-to {
    height: 300px !important;
    animation: slideOut 0.3s forwards !important;
  }
  .from-results-to-vehicle-leave-to > * {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .from-results-to-vehicle-enter-active {
    animation: slideIn 0.3s;
  }
}
.from-vehicle-to-results-leave-to {
  height: 300px !important;
}
.from-vehicle-to-results-leave-to > * {
  animation: fadeOut 0.3s forwards !important;
}
@media (min-width: 768px) {
  .from-vehicle-to-results-leave-to {
    height: auto;
    animation: slideOut 0.3s forwards !important;
  }
  .from-vehicle-to-results-leave-to > * {
    animation: none;
  }
}
.ride-hailing-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.ride-hailing-container.vehicles-container {
  height: calc(100vh - 64px);
}
@media (max-width: 768px) {
  .map.short {
    height: 60vh;
  }
}
.back-btn {
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 2;
  border-radius: 50%;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.2);
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes slideOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -300px;
  }
}
@-webkit-keyframes slideOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -300px;
  }
}
@-o-keyframes slideOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -300px;
  }
}
@keyframes slideOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -300px;
  }
}
@-moz-keyframes slideIn {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}
@-o-keyframes slideIn {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes slideIn {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}
/*# sourceMappingURL=src/pages/ondemand/ride-hailing/layout.css.map */