.detail {
  font-size: 14px;
}
.vehicle {
  height: 80px;
  padding: 10px 8px;
  transition: transform 0.2s ease-in;
  cursor: pointer;
}
.img-container {
  width: 72px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-price {
  color: #1e88e5;
  border-color: #1e88e5;
}
.vehicle:hover .btn-price {
  background: #1e88e5 !important;
  border-color: #1e88e5;
  color: #fff;
}
/*# sourceMappingURL=src/pages/ondemand/ride-hailing/vehicle-card.css.map */