
























































































































































































































.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.ride-form
  margin 0
  width 100vw
  position: absolute;
  overflow-y scroll
  transition .3s
  padding 0 16px
  z-index 1

  @media (min-width: 768px)
    width 20vw
    max-width 420px
    height 56px
    top 70%
    right: 50%;
    transform: translate(50%, -50%);

.ride-form.full
  @media (min-width: 768px)
    height: 60vh;
    top 50%
    right 50%
    width 33vw
    transform: translate(50%, -50%);

.ride-form.short
  @media (min-width: 768px)
    transform: translate(0%, -0%);
    min-height 320px
    top 16px
    right 16px
    width 18vw

.ride-form.results
  transform: translateY(-110vh);
  min-height 178px
  height auto
  @media (min-width: 768px)
    top: 16px;
    transform: translate(0,0);
    right: 16px;
    width: 300px;

.ride-form.results.full
  transform: translate(0,0);
  height auto

h5
  width 100%
  position absolute
  font-size 16px
  padding 10px
  cursor pointer

  i
    font-size 36px

  span
    vertical-align bottom

.time
  padding 10px
  z-index 100
  width: 100vw;
  height: 72px;
  background: white;
  margin: 0;
  position: fixed;
  transition bottom .3s, height .3s

  @media (min-width: 768px)
    position: absolute;
    width 624px
    left: 50%
    transform: translateX(-50%)

.time.short
  bottom 0
.time.short.results
  bottom -150px

.time-visible.full
  bottom -135px

.btn-search
  background var(--q-color-primary, #218AE5)
  color white
  width 100%
  transition width .3s

.inline-btn-search
  margin-bottom 16px
  animation-duration .3s!important

.btn-time
  background var(--q-color-primary, #218AE5)
  color white
  width: 100%;
