














































.text
  font-size 13px
  position absolute
  width 100%
  top 8px
  left 0
  text-align center
