









































































@keyframes fadeOut {
  0%   { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes slideOut {
  0% { bottom: 0 }
  100% { bottom: -300px }
}

@keyframes slideIn {
  0% { bottom: -300px }
  100% { bottom: 0 }
}

.to-results-enter-active, .to-results-leave-active {
  transition: opacity 1s;
}
.to-results-enter, .to-results-leave-to {
  opacity: 0;
}

.to-results-enter-to {
  opacity: 1;
}

.from-results-to-vehicle-leave-active, .from-vehicle-to-results-leave-active
  transition height .3s, opacity .3s, bottom .3s
  & > *
    transition opacity .3s!important

.from-results-to-vehicle-leave-to
  height: calc(100vh - 64px)!important;
  bottom: 0;
  position: absolute!important;
  background white

  & > *
    opacity 0

  @media (min-width 768px)
    height 300px!important
    animation slideOut .3s forwards!important

    & > *
      opacity 1

.from-results-to-vehicle-enter-active
  @media (min-width 768px)
    animation slideIn .3s

.from-vehicle-to-results-leave-to
  height: 300px!important
  & > *
    animation: fadeOut .3s forwards!important

  @media (min-width 768px)
    height auto
    animation: slideOut .3s forwards!important
    & > *
      animation none

.ride-hailing-container
  position relative
  height 100vh
  overflow: hidden
  &.vehicles-container
    height calc(100vh - 64px)
.map.short
  @media (max-width 768px)
    height 60vh

.back-btn
  position absolute
  left 5px
  top 10px
  z-index 2
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  border 1px solid rgba(0, 0, 0, 0.2)
