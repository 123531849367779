.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.ride-form {
  margin: 0;
  width: 100vw;
  position: absolute;
  overflow-y: scroll;
  transition: 0.3s;
  padding: 0 16px;
  z-index: 1;
}
@media (min-width: 768px) {
  .ride-form {
    width: 20vw;
    max-width: 420px;
    height: 56px;
    top: 70%;
    right: 50%;
    transform: translate(50%, -50%);
  }
}
@media (min-width: 768px) {
  .ride-form.full {
    height: 60vh;
    top: 50%;
    right: 50%;
    width: 33vw;
    transform: translate(50%, -50%);
  }
}
@media (min-width: 768px) {
  .ride-form.short {
    transform: translate(0%, 0%);
    min-height: 320px;
    top: 16px;
    right: 16px;
    width: 18vw;
  }
}
.ride-form.results {
  transform: translateY(-110vh);
  min-height: 178px;
  height: auto;
}
@media (min-width: 768px) {
  .ride-form.results {
    top: 16px;
    transform: translate(0, 0);
    right: 16px;
    width: 300px;
  }
}
.ride-form.results.full {
  transform: translate(0, 0);
  height: auto;
}
h5 {
  width: 100%;
  position: absolute;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
}
h5 i {
  font-size: 36px;
}
h5 span {
  vertical-align: bottom;
}
.time {
  padding: 10px;
  z-index: 100;
  width: 100vw;
  height: 72px;
  background: #fff;
  margin: 0;
  position: fixed;
  transition: bottom 0.3s, height 0.3s;
}
@media (min-width: 768px) {
  .time {
    position: absolute;
    width: 624px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.time.short {
  bottom: 0;
}
.time.short.results {
  bottom: -150px;
}
.time-visible.full {
  bottom: -135px;
}
.btn-search {
  background: var(--q-color-primary, #218ae5);
  color: #fff;
  width: 100%;
  transition: width 0.3s;
}
.inline-btn-search {
  margin-bottom: 16px;
  animation-duration: 0.3s !important;
}
.btn-time {
  background: var(--q-color-primary, #218ae5);
  color: #fff;
  width: 100%;
}
/*# sourceMappingURL=src/pages/ondemand/ride-hailing/form.css.map */