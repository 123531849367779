.vehicle-card-title .q-card-section {
  display: flex;
  justify-content: space-between;
}
.vehicle > * {
  animation: fadeIn 0.3s forwards;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=src/pages/ondemand/ride-hailing/vehicle.css.map */