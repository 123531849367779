.slide {
  padding: 0;
  overflow: hidden;
}
.name {
  padding: 10px;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 20px;
}
.carousel {
  width: 100%;
}
.scrolling-content {
  height: 256px;
}
/*# sourceMappingURL=src/pages/ondemand/ride-hailing/vehicle-type-list.css.map */